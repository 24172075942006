<template>
	<div class="card-container">
		<v-card class="mx-auto" color="secondary" dark>
			<v-card-text class="text-h6 font-weight-bold">
				{{ data.description }}
			</v-card-text>
			<v-card-actions>
				<v-list-item class="grow">
					<v-list-item-avatar color="grey darken-3">
						<v-img
							class="elevation-6"
							alt=""
							:src="comment.owner.image"
						></v-img>
					</v-list-item-avatar>
					<v-list-item-content>
						<v-list-item-title
							>{{ `${data.owner.firstName} ${data.owner.lastName}` }}
						</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</v-card-actions>
			<v-icon v-if="userId === data.owner.id" small @click="openPopup(data.id)">
				mdi-close-thick
			</v-icon>
		</v-card>
	</div>
</template>

<script>
export default {
	name: "Comment",
	data: () => ({
		data: {
			description: "",
			owner: {
				firstName: "",
				lastName: "",
			},
		},
	}),
	props: {
		openDelete: Function,
		comment: Object,
		userId: String,
	},
	mounted() {
		this.data = this.comment;
	},
	methods: {
		openPopup(id) {
			this.openDelete("", id);
		},
	},
};
</script>

<style scoped lang="scss">
.mx-auto {
	margin-bottom: 15px;
}
.card-container {
	display: flex;
	.v-card {
		max-width: 100%;
		position: relative;
		.v-card__text {
			width: 98%;
		}
	}
	.mdi-close-thick {
		position: absolute;
		top: 7px;
		opacity: 0.7;
		right: 7px;
		font-size: 20px !important;
		transition: 0.1 opacity ease-in;
		&:hover {
			opacity: 1;
		}
	}
}
.row {
	width: 100%;
	.v-sheet {
		width: 100%;
	}
}
</style>
