import axios from "axios";
import getBaseUrl from "./settings-service";

export default class UserFriendService {
  getFriends(id) {
    return axios.get(`${getBaseUrl()}users/${id}/friends`).then((response) => {
      let data = response.data;
      return data["hydra:member"];
    });
  }
  getFriendsPendingSends(id) {
    return axios
      .get(`${getBaseUrl()}users/${id}/pending_senders`)
      .then((response) => {
        let data = response.data;
        return data["hydra:member"];
      });
  }
  getFriendsPendingReceives(id) {
    return axios
      .get(`${getBaseUrl()}users/${id}/pending_recipients`)
      .then((response) => {
        let data = response.data;
        return data["hydra:member"];
      });
  }
  delete(id) {
    return axios
      .delete(`${getBaseUrl()}user_friends/${id}`)
      .then((response) => {
        return response;
      });
  }
  patch(id) {
    return axios
      .patch(`${getBaseUrl()}user_friends/${id}/accept`, {})
      .then((response) => {
        return response;
      });
  }
  post(body) {
    return axios.post(`${getBaseUrl()}user_friends`, body).then((response) => {
      return response;
    });
  }
}
