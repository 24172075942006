<template>
	<div class="text-center">
		<v-progress-circular
			indeterminate
			:color="colorLoader"
		></v-progress-circular>
	</div>
</template>

<script>
export default {
	name: "Loader",
	data: () => ({
		colorLoader: "",
	}),
	props: {
		color: String,
	},
	created() {
		this.colorLoader = this.$props.color === "primary" ? "#17ac93" : "#000";
	},
};
</script>
