import axios from "axios";
import getBaseUrl from "./settings-service";

export default class AccountService {
  put(id, body) {
    return axios.put(`${getBaseUrl()}users/${id}`, body).then((response) => {
      return response;
    });
  }

  getCurrentUser() {
    return axios.get(`${getBaseUrl()}current_user`).then((response) => {
      return response.data;
    });
  }
}
