<template>
	<div class="text-center ma-2">
		<v-snackbar v-model="valuesToast.toastValue" :top="true">
			<v-alert :type="valuesToast.typeAlert">
				{{ valuesToast.text }}
			</v-alert>
		</v-snackbar>
	</div>
</template>

<script>
export default {
	name: "ToastMessage",
	data: () => ({
		snackbar: false,
		timeout: 1000,
	}),
	inject: ["valuesToast"],
};
</script>
