<template lang="html">
	<div v-show="showButton" class="back-button">
		<Button :function="goBack" value="Retour" color="#cbcbcb" />
	</div>
</template>

<script>
import Button from "../components/Button.vue";

export default {
	data: () => ({
		routes: [
			"/",
			"/subscription",
			"/connexion",
			"/events",
			"/friends",
			"/events",
			"/account",
		],
		showButton: true,
	}),
	methods: {
		goBack() {
			this.$router.go(-1);
		},
		checkButton(path) {
			if (this.routes.includes(path)) {
				this.showButton = false;
			} else {
				this.showButton = true;
			}
		},
	},
	components: {
		Button,
	},
	mounted() {
		this.checkButton(this.$route.path);
	},
	watch: {
		$route(to) {
			this.checkButton(to.path);
		},
	},
};
</script>

<style scoped>
.back-button {
	margin-bottom: 30px;
}
</style>
