<template>
	<v-btn
		:type="$props.type"
		:disabled="$props.disabled"
		@click="func"
		:color="this.$props.color"
	>
		{{ value }}
	</v-btn>
</template>

<script>
export default {
	name: "Button",
	props: {
		type: String,
		disabled: Boolean,
		value: String,
		function: Function,
		paramFunction: [String, Number, Array, Object],
		secondParamFunction: [String, Number, Array, Object],
		color: String,
	},
	methods: {
		func() {
			if (this.$props.function != undefined) {
				this.$props.function(
					this.$props.paramFunction,
					this.$props.secondParamFunction
				);
			}
		},
	},
};
</script>

<style scoped lang="scss">
button {
	transition: all 0.2s;
	padding: 0 20px !important;
	margin: 0 10px;
}
</style>
