<template>
	<div>
		<!-- Modals -->
		<Modal
			:onSubmit="onSubmitDeleteProposal"
			:dialog="openDeleteProposal"
			:cancel="switchModal"
			title="Es-tu sûr de vouloir supprimer cette proposition ?"
			nameConfirm="Supprimer"
		/>
		<Modal
			:onSubmit="onSubmitDeleteComment"
			:dialog="openDeleteComment"
			:cancel="switchModal"
			title="Es-tu sûr de vouloir supprimer ce commentaire ?"
			nameConfirm="Supprimer"
		/>
		<Modal
			v-if="!loading"
			:onSubmit="onSubmitPrice"
			:dialog="open"
			:cancel="switchModal"
			:initialValues="initialValues"
			title="Saisissez un montant"
			:nameConfirm="
				myReservation && myReservation.id ? 'Modifier' : 'Participer'
			"
		>
			<InputPrice
				rules="required"
				disabled=""
				:onSubmit="onSubmitPrice"
				fieldName="Montant"
				value="price"
			/>
		</Modal>
		<Modal
			:onSubmit="onSubmitDelete"
			:dialog="openDelete"
			:cancel="switchModal"
			title="Etes-vous sûr de vouloir retirer votre participation ?"
			nameConfirm="Retirer"
		/>
		<div class="header-component">
			<h1>Proposition</h1>
			<div class="switch-payed" v-if="proposal.status !== 'pending'">
				<span>Produit acheté ?</span>
				<v-switch
					@change="updateIsPayed"
					v-model="initialValues.isPayed"
					label=""
				></v-switch>
			</div>
		</div>
		<div class="container-component">
			<v-container>
				<v-row class="tables-event">
					<v-col cols="12" sm="5">
						<v-card class="mx-auto">
							<v-card-text>
								<div class="pb-3">
									<div :class="classStatus">
										{{ this.getLabelStatut }}
									</div>
								</div>
								<p class="text-h4 text--primary">
									{{ proposal.title }}
								</p>

								<div class="text--primary">
									{{ proposal.description }}
								</div>
								<p>
									Proposé par : {{ proposal.owner && proposal.owner.firstName }}
									{{ proposal.owner && proposal.owner.lastName }}
								</p>
							</v-card-text>
							<v-card-actions class="py-4">
								<Button
									v-if="proposal.owner && proposal.owner.id === userId"
									:function="goToUpdate"
									value="Modifier"
									color="secondary"
								/>

								<Button
									v-if="proposal.owner && proposal.owner.id === userId"
									value="Supprimer"
									color="error"
									:function="switchModal"
									paramFunction="delete"
								/>
							</v-card-actions>
						</v-card>
					</v-col>
					<v-col cols="12" sm="5">
						<v-card class="pa-2" outlined tile>
							<Table
								name="Particpations"
								:datas="reservationsUser"
								:header="participantsHeader"
								:switchModal="switchModal"
								buttonName="Participer"
								:itemPerPage="[5]"
							>
								<!-- Slot Header -->
								<template v-slot:headerTable>
									<div class="header-toolbar">
										<v-toolbar-title>Participants</v-toolbar-title>
										<v-divider class="mx-4" inset vertical></v-divider>
										<v-spacer></v-spacer>
										<Button
											:function="switchModal"
											:value="
												myReservation && myReservation.id
													? 'Modifier ma particpation'
													: 'Participer'
											"
											color="primary"
											paramFunction="addDesti"
											:secondParamFunction="null"
										/>
									</div>
								</template>
								<!-- Slot items -->
								<template v-slot:items="slotProps">
									<td :class="isUserLogged(slotProps.item.id)">
										{{ slotProps.item.firstName }}
									</td>
									<td :class="isUserLogged(slotProps.item.id)">
										{{ slotProps.item.lastName }}
									</td>
									<td :class="isUserLogged(slotProps.item.id)">
										{{ slotProps.item.price }}
									</td>
									<td v-if="slotProps.item.id === userId">
										<v-icon small @click="switchModal('deleteReser')">
											mdi-delete
										</v-icon>
									</td>
								</template>
							</Table>
						</v-card>
					</v-col>
				</v-row>
			</v-container>
			<div class="comments">
				<div class="pb-7 section-name">
					<h2>Commentaires</h2>
				</div>
				<div>
					<v-row>
						<VueMik
							:initialValues="initialValues"
							:onSubmit="onSubmitComment"
							v-slot="{ invalid }"
						>
							<div class="input-comment">
								<Input fieldName="Ecrire un commentaire..." value="comment" />
								<Button
									type="Submit"
									color="primary"
									:disabled="invalid"
									value="Commenter"
								/>
							</div>
						</VueMik>
					</v-row>
					<v-row v-if="proposal.comments">
						<CommentList
							:comments="proposal.comments"
							:openDelete="switchModal"
							:userId="userId"
						/>
					</v-row>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Table from "../../components/Table.vue";
import Button from "../../components/Button.vue";
import Input from "../../components/fields/Input.vue";
import VueMik from "../../components/VueMik.vue";
import Modal from "../../components/Modal.vue";
import InputPrice from "../../components/fields/InputPrice.vue";
import ProposalService from "../../services/proposal-service.js";
import ReservationService from "../../services/reservation-service.js";
import CommentService from "../../services/comment-service.js";
import CommentList from "./comments/CommentsList.vue";

const proposalService = new ProposalService();
const reservationService = new ReservationService();
const commentService = new CommentService();

export default {
	name: "ProposalShow",
	data: () => ({
		initialValues: {
			comment: "",
			price: "",
			isPayed: false,
		},
		idEvent: "",
		idProposal: "",
		userId: "",
		open: false,
		openDelete: false,
		openDeleteComment: false,
		openDeleteProposal: false,
		loading: false,
		proposal: {},
		reservations: [],
		commentDeleteId: "",
		myReservation: {},
		reservationsUser: [],
		participantsHeader: [
			{
				text: "Prénom",
				align: "start",
			},
			{ text: "Nom" },
			{ text: "Montant" },
			{ text: "Action" },
		],
	}),
	inject: ["valuesToast"],
	components: {
		Table,
		Button,
		CommentList,
		Input,
		InputPrice,
		Modal,
		VueMik,
	},
	mounted: async function () {
		this.idEvent = this.$route.params.idEvent;
		this.idProposal = this.$route.params.idProposal;
		this.userId = this.$store.state.user.id;
		this.friends = this.$store.state.friends;
		try {
			await this.getProposal();
		} catch (e) {
			this.$router.go(-1);
		}
	},
	methods: {
		goToUpdate: function () {
			this.$router.push({
				name: "ProposalModify",
				props: {
					idEvent: this.idEvent,
					idProposal: this.idProposal,
				},
			});
		},
		getProposal: async function () {
			this.loading = true;
			this.proposal = await proposalService.get(this.idProposal);
			this.initialValues.isPayed = this.proposal.isPayed;
			this.reservations = this.proposal.reservations;
			this.reservationsUser = this.reservations.map((reser) => {
				return {
					...reser.owner,
					price: reser.price + " €",
				};
			});
			this.myReservation = this.reservations.find(
				(reser) => reser.owner.id === this.userId
			);
			if (this.myReservation) {
				this.initialValues.price = this.myReservation.price;
			} else {
				this.initialValues.price = "";
			}
			if (this.proposal.reservations) {
				this.proposal.reservationsUser = this.proposal.reservations.map(
					(reser) => reser.owner
				);
			}
			this.loading = false;
		},
		onSubmitComment: async function (datas) {
			try {
				await commentService.post({
					proposal: this.proposal["@id"],
					description: datas.comment,
				});
				this.valuesToast.typeAlert = "success";
				this.valuesToast.text = "Le commentaire à bien été ajouté !";
				this.valuesToast.toastValue = true;
				this.initialValues.comment = "";
				this.getProposal();
			} catch (e) {
				this.valuesToast.typeAlert = "error";
				this.valuesToast.text =
					"Un problème est surevenu, veuillez contacter un administrateur.";
				this.valuesToast.toastValue = true;
			}
		},
		updateIsPayed: async function (value) {
			try {
				await proposalService.patchIsPayed(this.proposal.id, {
					isPayed: value,
				});
				this.valuesToast.typeAlert = "success";
				this.valuesToast.text = "La proposition à bien été modifiée !";
				this.valuesToast.toastValue = true;
				this.openDeleteComment = false;
				this.getProposal();
			} catch (e) {
				this.valuesToast.typeAlert = "error";
				this.valuesToast.text =
					"Un problème est surevenu, veuillez contacter un administrateur.";
				this.valuesToast.toastValue = true;
			}
		},
		onSubmitPrice: async function (datas) {
			try {
				if (this.myReservation && this.myReservation.id) {
					await reservationService.patchPrice(this.myReservation.id, {
						price: +datas.price,
					});
					this.open = false;
					this.valuesToast.typeAlert = "success";
					this.valuesToast.text = "Le montant a bien été modifié !";
					this.valuesToast.toastValue = true;
				} else {
					await reservationService.post({
						proposal: this.proposal["@id"],
						price: +datas.price,
					});
					this.open = false;
					this.valuesToast.typeAlert = "success";
					this.valuesToast.text = "Votre particpation a bien été enregistrée !";
					this.valuesToast.toastValue = true;
				}
				this.getProposal();
			} catch (e) {
				this.valuesToast.typeAlert = "error";
				this.valuesToast.text =
					"Un problème est surevenu, veuillez contacter un administrateur.";
				this.valuesToast.toastValue = true;
			}
		},
		onSubmitDelete: async function () {
			try {
				await reservationService.delete(this.myReservation.id);

				this.openDelete = false;
				this.valuesToast.typeAlert = "success";
				this.valuesToast.text = "La participation a bien été supprimée";
				this.valuesToast.toastValue = true;
			} catch (e) {
				this.valuesToast.typeAlert = "error";
				this.valuesToast.text =
					"Un problème est surevenu, veuillez contacter un administrateur.";
				this.valuesToast.toastValue = true;
			}
			this.getProposal();
		},
		onSubmitDeleteProposal: async function () {
			try {
				await proposalService.delete(this.proposal.id);
				this.openDelete = false;
				this.valuesToast.typeAlert = "success";
				this.valuesToast.text = "La proposition a bien été supprimée";
				this.valuesToast.toastValue = true;
				this.$router.push("/events/" + this.idEvent);
			} catch (e) {
				this.valuesToast.typeAlert = "error";
				this.valuesToast.text =
					"Un problème est surevenu, veuillez contacter un administrateur.";
				this.valuesToast.toastValue = true;
			}
		},
		onSubmitDeleteComment: async function () {
			try {
				await commentService.delete(this.commentDeleteId);

				this.valuesToast.typeAlert = "success";
				this.valuesToast.text = "Le commentaire a bien été supprimée";
				this.valuesToast.toastValue = true;
				this.commentDeleteId = "";
				this.openDeleteComment = false;
				this.getProposal();
			} catch (e) {
				this.valuesToast.typeAlert = "error";
				this.valuesToast.text =
					"Un problème est surevenu, veuillez contacter un administrateur.";
				this.valuesToast.toastValue = true;
			}
		},
		switchModal(label, commentId) {
			if (label === "deleteReser" || this.openDelete) {
				this.openDelete = !this.openDelete;
			} else if (label === "delete" || this.openDeleteProposal) {
				this.openDeleteProposal = !this.openDeleteProposal;
			} else if (commentId || this.openDeleteComment) {
				this.openDeleteComment = !this.openDeleteComment;
				this.commentDeleteId = commentId;
			} else {
				this.open = !this.open;
			}
		},
		isUserLogged: function (id) {
			return this.userId === id ? "my-reservation" : "";
		},
	},
	computed: {
		getTotalPrice: function () {
			const price = this.proposal.price;

			const reducer = (previousValue, currentValue) =>
				previousValue + currentValue.price;
			const total = this.proposal.reservations.reduce(reducer, 0);

			return total + " / " + price + "€";
		},
		classStatus: function () {
			return `status status-${this.proposal.status}`;
		},
		getLabelStatut: function () {
			switch (this.proposal.status) {
				case "pending":
					return this.getTotalPrice;
				case "complete":
					return `Montant atteint (${this.proposal.price}€)`;
				default:
					return `Acheté (${this.proposal.price}€)`;
			}
		},
	},
};
</script>

<style scoped lang="scss">
$primary-color: #17ac93;
$secondary-color: #1a6573;
.header-component {
	display: flex;
	justify-content: space-between;
	.switch-payed {
		display: flex;
		align-items: center;
	}
}
.mdi-pencil {
	font-size: 25px !important;
	color: $primary-color !important;
}
.my-reservation {
	font-weight: bold;
}
.input-comment {
	display: flex;
	margin-bottom: 20px;
}
.event-description {
	background-color: white;
	border-radius: 7px;
	padding: 30px 30px;
	margin-bottom: 50px;
}
.tables-event {
	justify-content: space-between;
}
.container {
	max-width: 100%;
	margin-bottom: 50px;
}
.v-sheet {
	border: none !important;
}
.suggests {
	margin: auto;
}
.section-name {
	display: flex;
}
.status {
	color: white;
	padding: 5px 20px;
	font-weight: bold;
	border-radius: 100px;

	&-pending {
		background-color: #e77e22;
	}
	&-paid {
		background-color: #26b05f;
	}
	&-complete {
		background-color: #2880b9;
	}
}
</style>
