function getArrayUserIds(array) {
	return array.map((item) => item.person["@id"]);
}

function formatUserArrayForApi(array) {
	return array.map((item) => {
		return {
			person: item,
		};
	});
}

export { getArrayUserIds, formatUserArrayForApi };
