<template>
  <validation-provider
      v-slot="{ errors }"
      :name="$props.fieldName"
      :onSubmit="onSubmit"
      :rules="rules"
  >
    <v-textarea
        name="input-7-1"
        filled
        :label="fieldName"
        auto-grow
        :error-messages="errors"
        class="event-textarea"
        v-model="fieldValue"
    ></v-textarea>
  </validation-provider>
</template>

<script>
import { ValidationProvider } from 'vee-validate'

export default {
  name: 'AreaText',
  data: () => ({
    fieldValue: ''
  }),
  inject: ['values'],
  components: {
    ValidationProvider
  },
  props: {
    onSubmit : Function,
    fieldName: String,
    value: String,
    rules: String
  },
  watch: {
    fieldValue: function(val) {
      this.values[this.$props.value] = val;
    },
  },
  mounted() {
    this.fieldValue = this.values[this.$props.value];
  }
}
</script>

<style scoped>
.event-textarea {
  width: 100% !important;
}
</style>
