<template>
	<div class="content">
		<slot />
	</div>
</template>

<script>
export default {
	name: "LayoutNoSideBar",
};
</script>

<style scoped>
.content {
	display: flex;
	justify-content: center;
	align-items: center;
}
</style>
