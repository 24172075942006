<template>
	<div class="container-component">
		<div class="event-form" v-if="datasLoaded">
			<h1>{{ nameH1 }}</h1>
			<VueMik
				:initialValues="initialValues"
				:onSubmit="onSubmit"
				v-slot="{ invalid }"
			>
				<div class="content-form">
					<v-row>
						<v-col cols="12" sm="6">
							<Input
								rules="required"
								fieldName="Nom de l'évènement"
								value="name"
								:onSubmit="onSubmit"
							/>
						</v-col>
						<v-col cols="12" sm="6">
							<DatePicker value="date" fieldName="Date de l'évènement" />
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12" sm="12">
							<AreaText
								fieldName="Description"
								value="description"
								:onSubmit="onSubmit"
							/>
						</v-col>
					</v-row>
					<v-row>
						<v-col>
							<Select
								v-if="!eventId"
								value="eventGuests"
								:listValues="friends"
								fieldName="Participants"
								:onSubmit="onSubmit"
								itemText="fullName"
							/>
						</v-col>
						<v-col>
							<Select
								v-if="!eventId"
								value="eventRecipients"
								:listValues="friends"
								fieldName="Destinataires"
								:onSubmit="onSubmit"
								itemText="fullName"
							/>
						</v-col>
					</v-row>
				</div>
				<Button
					type="Submit"
					:disabled="invalid"
					:value="nameButton"
					color="primary"
					v-show="!isLoading"
				/>
				<Loader color="primary" v-show="isLoading" />
			</VueMik>
		</div>
		<Loader color="primary" v-if="!datasLoaded" />
	</div>
</template>

<script>
import VueMik from "../../components/VueMik.vue";
import Input from "../../components/fields/Input.vue";
import DatePicker from "../../components/fields/DatePicker.vue";
import Button from "../../components/Button.vue";
import Select from "../../components/fields/Select.vue";
import AreaText from "../../components/fields/AreaText.vue";
import EventService from "../../services/event-service.js";
import Loader from "../../components/Loader";
import {
	getArrayUserIds,
	formatUserArrayForApi,
} from "../../helpers/array-helper.js";

const eventService = new EventService();

export default {
	name: "EventCreate",
	components: {
		VueMik,
		Input,
		DatePicker,
		Button,
		Select,
		AreaText,
		Loader,
	},
	data: () => ({
		nameH1: "Création d'un évènement",
		nameButton: "Créer",
		friends: [],
		eventId: "",
		isLoading: false,
		datasLoaded: false,
		initialValues: {
			name: "",
			description: "",
			date: "",
		},
	}),
	inject: ["valuesToast"],
	created: async function () {
		this.friends = this.$store.state.friends;
		if (this.$route.params.id) {
			this.eventId = this.$route.params.id;
			try {
				await this.getEvent();
			} catch (e) {
				this.$router.go(-1);
			}
			this.nameH1 = "Modification d'un évènement";
			this.nameButton = "Modifier";
		}
		this.datasLoaded = true;
	},
	methods: {
		getEvent: async function () {
			const event = await eventService.get(this.$route.params.id);

			if (!this.eventId) {
				const eventRecipients = getArrayUserIds(event.eventRecipients);
				const eventGuests = getArrayUserIds(event.eventGuests);
				this.initialValues = {
					...event,
					eventRecipients,
					eventGuests,
				};
			} else {
				this.initialValues = {
					name: event.name,
					description: event.description,
					date: event.date,
				};
			}
		},
		onSubmit: async function (datas) {
			if (!this.eventId) {
				if (datas.eventGuests) {
					datas.eventGuests = formatUserArrayForApi(datas.eventGuests);
				}
				if (datas.eventRecipients) {
					datas.eventRecipients = formatUserArrayForApi(datas.eventRecipients);
				}
			}

			this.isLoading = true;
			try {
				if (this.$route.params.id) {
					await eventService.put(datas, this.$route.params.id);
					this.valuesToast.typeAlert = "success";
					this.valuesToast.text = "L'événement a bien été modifié !";
					this.valuesToast.toastValue = true;
					this.$router.push("/events");
				} else {
					await eventService.post(datas);
					this.valuesToast.typeAlert = "success";
					this.valuesToast.text = "L'événement a bien été crée !";
					this.valuesToast.toastValue = true;
					this.$router.push("/events");
				}
			} catch (error) {
				this.valuesToast.typeAlert = "error";
				this.valuesToast.text =
					"Un problème est surevenu, veuillez contacter un administrateur.";
				this.valuesToast.toastValue = true;
			}
			this.isLoading = false;
		},
	},
};
</script>

<style scoped lang="scss">
.event-form {
	display: flex;
	flex-direction: column;
	background-color: rgb(255, 255, 255);
	border-radius: 5px;
	padding: 60px 60px;
	margin: auto;

	.content-form {
		padding: 30px 0;
	}
}
h1 {
	text-align: center;
}
.back-connexion {
	margin-top: 15px !important;
}
</style>
