import getBaseUrl from "./settings-service";
import axios from "axios";

export default class AuthService {
	login(body) {
		return axios
			.post(`${getBaseUrl()}authentication_token`, body)
			.then((response) => {
				let data = response.data;
				this.storeAuthInformations(data);
				this.updateAxiosAuthorization();
				return data;
			});
	}

	register(body) {
		return axios
			.post(`${getBaseUrl()}register`, body)
			.then((response) => response.data)
			.catch();
	}

	logout() {
		return "";
	}

	updateAxiosAuthorization() {
		axios.interceptors.request.use((config) => {
			config.headers.Authorization = "Bearer " + this.getAuthToken();

			return config;
		});
	}

	getCurrentUser() {
		return axios
			.get(`${getBaseUrl()}current_user`)
			.then((response) => {
				return response.data;
			})
			.catch();
	}

	removeAuthInformations() {
		localStorage.removeItem("auth");
		axios.interceptors.request.use((config) => {
			config.headers.Authorization = "";
			return config;
		});
	}

	updateAxiosInterceptors() {
		axios.interceptors.response.use(undefined, (error) => {
			const { status } = error.response;
			if (status === 401) {
				this.removeAuthInformations();
				window.location.href = "/connexion";
			}
			return Promise.reject(error);
		});
	}

	storeAuthInformations(data) {
		localStorage.setItem("auth", JSON.stringify(data));
	}

	getAuthToken() {
		let auth = localStorage.getItem("auth");
		return auth ? JSON.parse(auth).token : null;
	}

	refreshAxiosConfig() {
		this.updateAxiosAuthorization();
		this.updateAxiosInterceptors();
	}
}
