<template>
  <div>
    <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="date"
          :fieldName="$props.fieldName"
          prepend-icon="mdi-calendar"
          readonly
          v-bind="attrs"
          v-on="on"
        ></v-text-field>
      </template>
      <v-date-picker
        v-model="date"
        :active-picker.sync="activePicker"
        min="1950-01-01"
        @change="save"
        locale="fr"
      ></v-date-picker>
    </v-menu>
  </div>
</template>

<script>
  export default {
    name: 'DatePicker',
    data: () => ({
      activePicker: null,
      date: '',
      menu: false
    }),
    inject: ['values'],
    props: {
        fieldName: String,
        value: String
    },
    watch: {
      menu (val) {
        val && setTimeout(() => (this.activePicker = 'YEAR'))
      },
      activePicker (val) {
        console.log(val)
      },
      date (val) {
          this.values[this.$props.value] = val
      }
    },
    methods: {
      save (date) {
        this.$refs.menu.save(date)
      },
    },
    mounted(){
      let dateValue = this.values[this.$props.value];
      if (dateValue == "" || dateValue == null){
          this.date = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
      }else {
          this.date = dateValue
      }
    },
  }
</script>