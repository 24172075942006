import axios from "axios";
import getBaseUrl from "./settings-service";

export default class CommentService {
	post(body) {
		return axios.post(`${getBaseUrl()}comments`, body).then(() => {
			return;
		});
	}
	delete(id) {
		return axios.delete(`${getBaseUrl()}comments/${id}`).then((e) => {
			return e;
		});
	}
}
