<template>
	<div class="home">
		<h1>C'est l'accueil</h1>
	</div>
</template>

<script>
export default {
	name: "Home",
	data: () => ({
		//
	}),
};
</script>
