<template>
	<validation-provider
		v-slot="{ errors }"
		:name="$props.fieldName"
		:onSubmit="onSubmit"
		:rules="rules"
	>
		<v-switch
			v-model="fieldValue"
			:error-messages="errors"
			:label="fieldName"
		></v-switch>
	</validation-provider>
</template>

<script>
import { ValidationProvider } from "vee-validate";

export default {
	name: "SwitchButton",
	data() {
		return {
			fieldValue: null,
		};
	},
	inject: ["values"],
	components: {
		ValidationProvider,
	},
	props: {
		onSubmit: Function,
		fieldName: String,
		value: String,
		rules: String,
	},
	watch: {
		fieldValue: function (val) {
			this.values[this.$props.value] = val;
		},
	},
	mounted() {
		this.fieldValue = this.values[this.$props.value];
	},
};
</script>
