import axios from "axios";
import getBaseUrl from "./settings-service";

export default class EventGuestService {
	delete(id) {
		return axios.delete(`${getBaseUrl()}event_guests/${id}`).then(() => {
			return;
		});
	}
}
