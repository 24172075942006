<template>
	<v-app>
		<ToastMessage />
		<div id="app" :is="layout">
			<div id="main">
				<BackButton />
				<router-view />
			</div>
		</div>
	</v-app>
</template>

<script>
import BackButton from "./components/BackButton.vue";
import ToastMessage from "./components/ToastMessage.vue";

const layout_default = "default";

export default {
	name: "App",
	data: () => ({
		datas: {
			typeAlert: "success",
			text: "",
			toastValue: false,
		},
	}),
	provide() {
		return {
			valuesToast: this.datas,
		};
	},
	components: {
		BackButton,
		ToastMessage,
	},
	computed: {
		layout() {
			return (this.$route.meta.layout || layout_default) + "-layout";
		},
	},
};
</script>

<style lang="scss">
$primary-color: #17ac93;
$secondary-color: #1a6573;

#app {
	margin-top: -8px;
	min-height: 100vh;
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: #2c3e50;
	background-color: rgb(238, 238, 238);
	td {
		.v-icon {
			color: white;
			padding: 5px;
			border-radius: 4px;
		}
		.mdi-pencil {
			background-color: #17ac93;
			&:hover {
				background-color: #158a76;
			}
		}
		.mdi-check {
			background-color: #17ac93;
			&:hover {
				background-color: #158a76;
			}
		}
		.mdi-eye {
			background-color: #1a6573;
			&:hover {
				background-color: #124750;
			}
		}
		.mdi-delete {
			background-color: rgb(177, 35, 35);
			&:hover {
				background-color: rgb(139, 23, 23);
			}
		}
	}
	i {
		color: $secondary-color;
	}
	a {
		text-decoration: none;
		color: #2c3e50;
	}
	#main {
		padding: 50px;
		width: 100%;

		.container-component {
			width: 75%;
			margin: auto;
			height: 100%;
		}
	}
	.v-input {
		width: 300px;
		padding: 8px 15px;
	}
	.primary--text {
		color: #17ac93 !important;
	}
	.v-dialog {
		overflow: inherit;
	}
	.header-toolbar {
		display: flex;
		padding: 12px;
	}
}
.v-snack__content {
	padding: 0 !important;
	.v-alert {
		margin: 0;
	}
}
</style>
