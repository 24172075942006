function getPad(number) {
	return number >= 10 ? number : "0" + number;
}

export default function getDateTimeFormat(datetime, withHours = true) {
	const date = new Date(datetime);
	const day = getPad(date.getDate());
	const month = date.toLocaleString("default", { month: "long" });
	const year = date.getFullYear();

	let hours;
	let minutes;
	if (withHours) {
		hours = getPad(date.getHours());
		minutes = getPad(date.getMinutes());
	}

	return withHours
		? `${day} ${month} ${year} à ${hours}h${minutes}`
		: `${day} ${month} ${year}`;
}
