<template>
	<v-card>
		<v-data-table
			:headers="$props.header"
			:items="$props.datas"
			:search="search"
			:custom-sort="customSort"
			:footer-props="{
				'items-per-page-options': itemPerPage,
			}"
			:loading="loading"
			loading-text="Chargement... Veuillez patienter"
		>
			<template :item="item" v-slot:top>
				<slot name="headerTable"></slot>
				<v-toolbar flat>
					<v-text-field
						v-model="search"
						append-icon="mdi-magnify"
						label="Rechercher"
						single-line
						hide-details
					></v-text-field>
				</v-toolbar>
			</template>
			<template v-slot:body="{ items }">
				<tbody>
					<tr v-for="item in items" :key="item.id">
						<slot name="items" :item="item"></slot>
					</tr>
				</tbody>
			</template>
			<template slot="no-data">
				<v-alert> Aucun éléments </v-alert>
			</template>
		</v-data-table>
	</v-card>
</template>

<script>
export default {
	name: "Table",
	data: () => ({
		search: "",
	}),
	props: {
		header: Array,
		datas: Array,
		itemPerPage: Array,
		loading: Boolean,
		customSort: Function
	},
	methods: {

	}
	// created() {
	// 	setTimeout(() => (this.loading = false), 5000);
	// },
};
</script>

<style lang="scss">
.header-toolbar {
	display: flex;
	align-items: center;
	padding: 0 25px;
	hr {
		margin-bottom: 8px;
	}
}
.v-card {
	width: 80%;
	box-shadow: 0px 0px 3px 2px #e0e0e0 !important;
}

.v-sheet {
	width: 100%;
}
.v .verysmall-th {
	width: 11%;
}
.small-th {
	width: 15%;
}
.medium-th {
	width: 20%;
}
.long-th {
	width: 30%;
}
.verylong-th {
	width: 40%;
}
</style>
