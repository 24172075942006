<template>
	<v-card>
		<v-navigation-drawer
			v-model="drawer"
			:mini-variant.sync="mini"
			permanent
			class="sidebar-comp"
		>
			<v-list-item class="px-2 py-2 sidebar_top">
				<img
					class="logo_sidebar"
					src="../assets/img/logo_todogift.png"
					alt=""
				/>
				<img
					class="text_sidebar"
					src="../assets/img/text_todogift.png"
					alt=""
				/>
			</v-list-item>
			<v-divider></v-divider>
			<v-list dense>
				<v-list-item
					v-for="item in items"
					:key="item.title"
					link
					:class="item.class"
				>
					<router-link class="router-link" :to="item.link">
						<v-list-item-icon class="diconnect-bc">
							<v-icon>{{ item.icon }}</v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title>{{ item.title }}</v-list-item-title>
						</v-list-item-content>
					</router-link>
				</v-list-item>
			</v-list>
		</v-navigation-drawer>
	</v-card>
</template>

<script>
import AuthService from "../services/auth-service";

export default {
	name: "SideBar",
	data: () => ({
		drawer: true,
		items: [
			{ title: "Dashboard", icon: "mdi-home-city", link: "/" },
			{ title: "Amis", icon: "mdi-account", link: "/friends" },
			{ title: "Événements", icon: "mdi-gift", link: "/events" },
			{ title: "Mon compte", icon: "mdi-account-box", link: "/account" },
			{
				title: "Déconnexion",
				icon: "mdi-logout",
				link: "/home",
				class: "diconnect",
			},
		],
		mini: true,
	}),
	inject: ["valuesToast"],
	mounted() {
		const element = document.getElementsByClassName("diconnect")[0];
		element.addEventListener("click", () => {
			const authService = new AuthService();
			authService.removeAuthInformations();
			this.valuesToast.typeAlert = "success";
			this.valuesToast.text = "Vous êtes déconnecté !";
			this.valuesToast.toastValue = true;
			this.$router.push("connexion");
		});
	},
};
</script>

<style scoped lang="scss">
.sidebar-comp {
	height: 100vh;
}
.sidebar_top {
	display: flex;
	align-items: center;

	.logo_sidebar {
		width: 40px !important;
		height: 40px !important;
	}
	.text_sidebar {
		height: 40px !important;
		padding-left: 10px;
	}
}
.v-card {
	height: 100%;
}
.nav-links {
	margin-left: 20px;
	& > a {
		padding: 10px;
		margin-right: 20px;
		background-color: rgb(236, 236, 236) !important;
		border-radius: 3px;
		&:hover {
			background-color: rgb(186, 216, 228) !important;
		}
	}
}
.router-link {
	display: flex;
	width: 100%;

	div:nth-child(2) {
		padding-left: 10px;
	}
}
.diconnect {
	background-color: rgba(150, 17, 0, 0.144);
}
</style>
