<template>
	<validation-provider
		v-slot="{ errors }"
		:name="$props.fieldName"
		:onSubmit="onSubmit"
		:rules="rules"
	>
		<v-select
			v-model="fieldValue"
			:error-messages="errors"
			:items="listValues"
			attach
			:item-text="itemText"
			item-value="@id"
			:chips="multiple"
			:multiple="multiple"
			:label="$props.fieldName"
		></v-select>
	</validation-provider>
</template>

<script>
import { ValidationProvider } from "vee-validate";

export default {
	name: "Select",
	data: () => ({
		fieldValue: [],
	}),
	inject: ["values"],
	components: {
		ValidationProvider,
	},
	props: {
		onSubmit: Function,
		ValidationProvider,
		value: String,
		fieldName: String,
		rules: String,
		listValues: Array,
		itemText: String,
		multiple: {
			type: Boolean,
			default: true,
		},
	},
	watch: {
		fieldValue: function (val) {
			this.values[this.$props.value] = val;
		},
	},
	mounted() {
		this.fieldValue = this.values[this.$props.value];
	},
};
</script>
