import axios from "axios";
import getBaseUrl from "./settings-service";

export default class ProposalService {
	getEventProposals(id) {
		return axios
			.get(`${getBaseUrl()}events/${id}/proposals`)
			.then((response) => {
				let data = response.data;
				return data["hydra:member"];
			});
	}
	get(id) {
		return axios
			.get(`${getBaseUrl()}proposals/${id}`)
			.then((response) => response.data);
	}
	post(body) {
		return axios
			.post(`${getBaseUrl()}proposals`, body)
			.then((response) => response);
	}
	put(id, body) {
		return axios
			.put(`${getBaseUrl()}proposals/${id}`, body)
			.then((response) => response);
	}
	patchIsPayed(id, body) {
		return axios
			.patch(`${getBaseUrl()}proposals/${id}`, body)
			.then((response) => response);
	}
	delete(id) {
		return axios
			.delete(`${getBaseUrl()}proposals/${id}`)
			.then((response) => response);
	}
}
