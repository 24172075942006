<template>
	<div>
		<!-- Modals -->
		<Modal
			:onSubmit="onSubmit"
			:dialog="open"
			:cancel="switchModal"
			title="Es-tu sûr de vouloir supprimer cet évènement ?"
			nameConfirm="Supprimer"
		/>
		<!-- Component -->
		<div class="header-component">
			<h1>Evenements</h1>
			<router-link class="router-link" :to="'/events/create/'">
				<Button value="Ajouter un évènement" color="primary" />
			</router-link>
		</div>
		<div class="list-content">
			<v-card-text>
				<Table
					name="Liste d'amis"
					:datas="events"
					:header="eventsHeader"
					:switchModal="switchModal"
					:itemPerPage="[5, 10, 15, -1]"
					:loading="loading"
					:customSort="customSort"
				>
					<!-- Slot items -->
					<template v-slot:items="slotProps" class="testt">
						<td>{{ slotProps.item.name }}</td>
						<td>{{ slotProps.item.date | formatDate }}</td>
						<td>{{ slotProps.item.eventGuests.length + 1 }}</td>
						<td>{{ slotProps.item.eventRecipients.length }}</td>
						<td v-if="slotProps.item.owner.id != userId">
							{{
								`${slotProps.item.owner.firstName} ${slotProps.item.owner.lastName}`
							}}
						</td>
						<td v-else>Moi</td>
						<td>
							<router-link
								class="router-link"
								:to="'/events/' + slotProps.item.id"
							>
								<v-icon small class="mr-5">mdi-eye</v-icon>
							</router-link>
							<router-link
								v-if="slotProps.item.owner.id === userId"
								class="router-link"
								:to="'/events/edit/' + slotProps.item.id"
							>
								<v-icon small class="mr-5">mdi-pencil</v-icon>
							</router-link>
							<v-icon
								v-if="slotProps.item.owner.id === userId"
								small
								@click="switchModal(slotProps.item.id)"
								>mdi-delete</v-icon
							>
						</td>
					</template>
				</Table>
			</v-card-text>
		</div>
	</div>
</template>

<script>
import Table from "../../components/Table.vue";
import Button from "../../components/Button.vue";
import Modal from "../../components/Modal.vue";
import EventService from "../../services/event-service";
import getDateTimeFormat from "../../helpers/date-helper";
import truncate from "../../helpers/string-helper";

const eventService = new EventService();

export default {
	name: "EventList",
	components: {
		Table,
		Button,
		Modal,
	},
	data: () => ({
		open: false,
		loading: true,
		events: [],
		userId: "",
		item: {},
		eventsHeader: [
			{
				text: "Nom de l'évènement",
				align: "start",
				class: "long-th",
				value: "name",
			},
			{ text: "Date de l'évènement", value: "date" },
			{
				text: "Nombre de participants",
				value: "eventGuests.length",
			},
			{ text: "Nombre de destinataires", value: "actions" },
			{ text: "Créer par" },
			{
				text: "Actions",
				value: "actions",
				sortable: false,
				class: "verysmall-th",
			},
		],
	}),
	inject: ["valuesToast"],
	created: async function () {
		this.userId = this.$store.state.user.id;
		await this.getEvents();
	},
	methods: {
		customSort: function (items) {
			items.sort((a, b) => {
				return new Date(b.date) - new Date(a.date);
			});
			return items;
		},
		getEvents: async function () {
			this.loading = true;
			let events = await eventService.all(this.$store.state.user.id);
			let eventWhereGuests = await eventService.getEventForGuests();
			events = events.concat(eventWhereGuests);
			//events = this.formatDatas(events);
			this.events = events;
			this.loading = false;
		},
		formatDatas(events) {
			return events.map((event) => {
				return {
					...event,
					name: truncate(event.name, 100),
				};
			});
		},
		onSubmit: async function () {
			this.open = false;
			await eventService.delete(this.item);
			this.getEvents();
			this.valuesToast.typeAlert = "success";
			this.valuesToast.text = "L'événement a bien été supprimé !";
			this.valuesToast.toastValue = true;
		},
		switchModal(item = null) {
			if (typeof item == "number") {
				this.item = item;
				this.open = !this.open;
			} else {
				this.item = "";
				this.open = false;
			}
		},
	},
	filters: {
		formatDate: function (date) {
			return getDateTimeFormat(date);
		},
	},
};
</script>

<style scoped lang="scss">
$primary-color: #17ac93;
$secondary-color: #1a6573;

.header-component {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	button {
		margin: 0 30px;
	}
}
.list-content {
	display: flex;
	justify-content: center;
	padding-top: 20px;
}

.v-card {
	box-shadow: none !important;
	width: 80%;
}
.v-tab {
	padding: 0 40px;
}
.v-tab--active {
	background-color: $secondary-color;
	color: white !important;
}
</style>
