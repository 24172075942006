<template>
  <div class="modal" v-show="dialog">
    <div class="background-modal" @click="cancel"></div>
    <v-card width="500" >
      <v-card-title>
        <span class="text-h6">{{title}}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <VueMik 
                :initialValues="initialValues"
                :onSubmit="onSubmit"
                v-slot="{ invalid }"
              >
                <slot />
                <Button value="Annuler" :function="cancel" color="back"/>
                <Button type="Submit" :disabled="invalid" :value="nameConfirm" color="primary" />
              </VueMik>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
  import VueMik from '../components/VueMik.vue';
  import Button from '../components/Button.vue';

  export default {
    name: 'Modal',
    components: {
      VueMik,
      Button
    },
    props: {
      onSubmit: Function,
      initialValues: Object,
      dialog: Boolean,
      cancel: Function,
      title: String,
      nameConfirm: String
    }
  }
</script>

<style scoped lang="scss">
  .modal {
    display: flex;
    justify-content: center;
    align-items: center;
    
    .background-modal {
      position: absolute;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.267);
      z-index: 2;
    }
    .v-card {
      margin-top: 500px;
      position: absolute;
      z-index: 3;
      justify-content: center;
    }
  }
</style>