import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
//Auth
import Subscription from "../views/auth/Subscription.vue";
import Connexion from "../views/auth/Connexion.vue";
//Friends
import FriendList from "../views/friends/List.vue";
//Events
import EventList from "../views/events/List.vue";
import EventCreate from "../views/events/Create.vue";
import EventShow from "../views/events/Show.vue";
//Suggests
import ProposalShow from "../views/proposals/Show.vue";
import ProposalCreate from "../views/proposals/Create.vue";
//Account
import AccountUpdate from "../views/account/Update.vue";

Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		name: "Home",
		component: Home,
	},
	//Auth
	{
		path: "/subscription",
		name: "Subscription",
		meta: { layout: "nosidebar" },
		component: Subscription,
	},
	{
		path: "/connexion",
		name: "Connexion",
		meta: { layout: "nosidebar" },
		component: Connexion,
	},
	//Friends
	{
		path: "/friends",
		name: "FriendList",
		component: FriendList,
	},
	//Events
	{
		path: "/events",
		name: "EventList",
		component: EventList,
	},
	{
		path: "/events/create",
		name: "EventCreate",
		component: EventCreate,
	},
	{
		path: "/events/edit/:id",
		name: "EventModify",
		component: EventCreate,
	},
	{
		path: "/events/:id",
		name: "EventShow",
		component: EventShow,
	},
	//Proposals
	{
		path: "/events/:idEvent/proposals/create",
		name: "ProposalCreate",
		component: ProposalCreate,
	},
	{
		path: "/events/:idEvent/proposals/:idProposal/edit",
		name: "ProposalModify",
		component: ProposalCreate,
	},
	{
		path: "/events/:idEvent/proposals/:idProposal",
		name: "SuggestShow",
		component: ProposalShow,
	},
	//Account
	{
		path: "/account",
		name: "AccountUpdate",
		component: AccountUpdate,
	},
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
});

export default router;
