import axios from "axios";
import getBaseUrl from "./settings-service";

export default class EventRecipientService {
	delete(id) {
		return axios.delete(`${getBaseUrl()}event_recipients/${id}`).then(() => {
			return;
		});
	}
}
