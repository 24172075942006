<template>
	<validation-provider
		v-slot="{ errors }"
		:name="$props.fieldName"
		:onSubmit="onSubmit"
		:rules="rules"
	>
		<v-text-field
			v-model="fieldValue"
			:error-messages="errors"
			prefix="€"
			:label="$props.fieldName"
			type="Number"
			:min="min"
		></v-text-field>
	</validation-provider>
</template>

<script>
import { ValidationProvider } from "vee-validate";

export default {
	name: "InputPrice",
	data: () => ({
		fieldValue: "",
	}),
	inject: ["values"],
	components: {
		ValidationProvider,
	},
	props: {
		onSubmit: Function,
		fieldName: String,
		value: String,
		rules: String,
		min: {
			type: Number,
			default: 0,
		},
	},
	watch: {
		fieldValue: function (val) {
			this.values[this.$props.value] = val;
		},
	},
	mounted() {
		this.fieldValue = this.values[this.$props.value];
	},
};
</script>
