<template>
	<div class="container-component">
		<div v-if="datasLoaded" class="event-form">
			<h1>Mon compte</h1>
			<VueMik
				:initialValues="initialValues"
				:onSubmit="onSubmit"
				v-slot="{ invalid }"
			>
				<div class="content-form">
					<v-row>
						<v-col cols="12" sm="6">
							<Input
								rules="required|min:2"
								fieldName="Prénom"
								value="firstName"
								:onSubmit="onSubmit"
							/>
						</v-col>
						<v-col cols="12" sm="6">
							<Input
								rules="required|min:2"
								fieldName="Nom"
								value="lastName"
								:onSubmit="onSubmit"
							/>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12" sm="6">
							<DatePicker fieldName="Date de naissance" value="birthdate" />
						</v-col>
						<v-col cols="12" sm="6">
							<Input
								rules="required|email"
								fieldName="Email"
								value="email"
								:onSubmit="onSubmit"
								:disabled="true"
							/>
						</v-col>
					</v-row>
					<v-row>
						<v-col>
							<Input
								rules="integer|min:10|max:10"
								fieldName="Téléphone"
								value="mobile"
								:onSubmit="onSubmit"
							/>
						</v-col>
						<v-col>
							<Select
								rules="required"
								value="gender"
								:listValues="genreValues"
								fieldName="Genre"
								:onSubmit="onSubmit"
								:multiple="false"
							/>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12" sm="6">
							<UploadImage
								fieldName="Image"
								value="image"
								:onSubmit="onSubmit"
							/>
						</v-col>
					</v-row>
				</div>
				<Button
					type="Submit"
					:disabled="invalid"
					value="Modifier"
					color="primary"
				/>
			</VueMik>
		</div>
	</div>
</template>

<script>
import VueMik from "../../components/VueMik.vue";
import Input from "../../components/fields/Input.vue";
import DatePicker from "../../components/fields/DatePicker.vue";
import Button from "../../components/Button.vue";
import Select from "../../components/fields/Select.vue";
import UploadImage from "../../components/fields/UploadImage.vue";
import AccountService from "../../services/account-service.js";

const accountService = new AccountService();

export default {
	name: "AccountUpdate",
	data: () => ({
		text: "",
		datasLoaded: false,
		typeAlert: null,
		toastValue: false,
		initialValues: {
			firstName: "",
			lastName: "",
			birthdate: "",
			email: "",
			mobile: "",
			gender: "",
			image: "",
		},
		genreValues: ["Femme", "Homme"],
	}),
	inject: ["valuesToast"],
	components: {
		VueMik,
		Input,
		DatePicker,
		Button,
		Select,
		UploadImage,
	},
	created: function () {
		this.getCurrentUser();
	},
	methods: {
		onSubmit: async function (datas) {
			datas = {
				...datas,
				image: datas.image.image ? datas.image.image : null,
				imageName: datas.image.imageName
					? datas.image.imageName.split(".")[0]
					: null,
			};
			await accountService.put(this.$store.state.user.id, datas);
			this.valuesToast.typeAlert = "success";
			this.valuesToast.text = "Les données ont été mises à jour";
			this.valuesToast.toastValue = true;
		},
		getCurrentUser: async function () {
			let current_user = await accountService.getCurrentUser();
			this.initialValues = {
				...current_user,
				birthdate: this.formatDate(new Date(current_user.birthdate)),
			};
			this.datasLoaded = true;
		},
		formatDate(date) {
			var d = new Date(date),
				month = "" + (d.getMonth() + 1),
				day = "" + d.getDate(),
				year = d.getFullYear();

			if (month.length < 2) month = "0" + month;
			if (day.length < 2) day = "0" + day;

			return [year, month, day].join("-");
		},
	},
};
</script>

<style scoped lang="scss">
.event-form {
	display: flex;
	flex-direction: column;
	background-color: rgb(255, 255, 255);
	border-radius: 5px;
	padding: 60px 60px;
	margin: auto;

	.content-form {
		padding: 30px 0;
	}
}
h1 {
	text-align: center;
}
.back-connexion {
	margin-top: 15px !important;
}
</style>
