<template>
	<div class="container-component">
		<div class="event-form" v-if="datasLoaded">
			<h1>{{ nameH1 }}</h1>
			<VueMik
				:initialValues="initialValues"
				:onSubmit="onSubmit"
				v-slot="{ invalid }"
			>
				<div class="content-form">
					<v-row>
						<v-col cols="12" sm="6">
							<Input
								rules="required"
								fieldName="Nom de la proposition"
								value="title"
								:onSubmit="onSubmit"
							/>
						</v-col>
						<v-col cols="12" sm="6">
							<InputPrice
								rules="required"
								:onSubmit="onSubmit"
								fieldName="Montant"
								value="price"
							/>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12" sm="12">
							<AreaText
								rules="required"
								fieldName="Description de la proposition"
								value="description"
								:onSubmit="onSubmit"
							/>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12" sm="6">
							<UploadImage
								fieldName="Image"
								value="image"
								:onSubmit="onSubmit"
							/>
						</v-col>
						<v-col cols="12" sm="6" v-show="idProposal === ''">
							<InputPrice
								:onSubmit="onSubmit"
								fieldName="Participation"
								value="reservations"
							/>
						</v-col>
					</v-row>
					<v-row v-show="showSwitchPayed && idProposal === ''">
						<v-col cols="12" sm="6">
							<SwitchButton
								fieldName="Acheté"
								value="isPayed"
								:onSubmit="onSubmit"
							/>
						</v-col>
					</v-row>
				</div>
				<Button
					type="Submit"
					:disabled="invalid"
					:value="nameButton"
					color="primary"
					v-show="!isLoading"
				/>
				<Loader color="primary" v-show="isLoading" />
			</VueMik>
		</div>
		<Loader color="primary" v-if="!datasLoaded" />
	</div>
</template>

<script>
import VueMik from "../../components/VueMik.vue";
import Input from "../../components/fields/Input.vue";
import InputPrice from "../../components/fields/InputPrice.vue";
import Button from "../../components/Button.vue";
import AreaText from "../../components/fields/AreaText.vue";
import SwitchButton from "../../components/fields/SwitchButton.vue";
import UploadImage from "../../components/fields/UploadImage.vue";
import ProposalService from "../../services/proposal-service.js";
import Loader from "../../components/Loader";

const proposalService = new ProposalService();

export default {
	name: "ProposalCreate",
	data: () => ({
		nameH1: "Création d'une proposition",
		nameButton: "Créer",
		initialValues: {
			title: "",
			description: "",
			price: null,
			isPayed: false,
			image: "",
			reservations: "",
		},
		isLoading: false,
		eventId: "",
		idProposal: "",
		user: {},
		datasLoaded: false,
	}),
	components: {
		VueMik,
		Input,
		InputPrice,
		Button,
		AreaText,
		SwitchButton,
		UploadImage,
		Loader,
	},
	inject: ["valuesToast"],
	created: async function () {
		this.eventId = this.$route.params.idEvent;
		if (this.$route.params.idProposal && this.$store.state.user) {
			this.idProposal = this.$route.params.idProposal;
			this.user = this.$store.state.user;
			this.nameH1 = "Modification d'une suggestion";
			this.nameButton = "Modifier";
			try {
				await this.getProposal();
			} catch (e) {
				this.$router.go(-1);
			}
		}
		this.datasLoaded = true;
	},
	methods: {
		getProposal: async function () {
			let proposal = await proposalService.get(this.idProposal);
			this.initialValues = {
				...proposal,
			};
		},
		onSubmit: async function (datas) {
			if (
				datas.reservations &&
				datas.reservations !== "" &&
				datas.reservations > 0
			) {
				datas = {
					event: "/api/events/" + this.eventId,
					title: datas.title,
					description: datas.description,
					isPayed: datas.isPayed,
					image: datas.image.image ? datas.image.image : null,
					imageName: datas.image.imageName
						? datas.image.imageName.split(".")[0]
						: null,
					price: +datas.price,
					reservations: [
						{
							price: +datas.reservations,
						},
					],
				};
			} else {
				datas = {
					event: "/api/events/" + this.eventId,
					title: datas.title,
					description: datas.description,
					isPayed: datas.isPayed,
					image: datas.image.image ? datas.image.image : null,
					imageName: datas.image.imageName
						? datas.image.imageName.split(".")[0]
						: null,
					price: +datas.price,
				};
			}

			this.isLoading = true;
			try {
				if (this.idProposal) {
					await proposalService.put(this.idProposal, datas);

					this.valuesToast.typeAlert = "success";
					this.valuesToast.text = "La proposition a bien été modifiée !";
					this.valuesToast.toastValue = true;
					this.$router.push(
						`/events/${this.eventId}/proposals/${this.idProposal}`
					);
				} else {
					await proposalService.post(datas);
					this.valuesToast.typeAlert = "success";
					this.valuesToast.text = "La proposition a bien été créée !";
					this.valuesToast.toastValue = true;
					this.$router.push("/events/" + this.eventId);
				}
			} catch (error) {
				this.valuesToast.typeAlert = "error";
				this.valuesToast.text =
					"Un problème est surevenu, veuillez contacter un administrateur.";
				this.valuesToast.toastValue = true;
			}
			this.isLoading = false;
		},
	},
	computed: {
		showSwitchPayed: function () {
			return (
				this.initialValues.price &&
				+this.initialValues.price === +this.initialValues.reservations
			);
		},
	},
};
</script>

<style scoped lang="scss">
.event-form {
	display: flex;
	flex-direction: column;
	background-color: rgb(255, 255, 255);
	border-radius: 5px;
	padding: 60px 0;
	margin: auto;

	.content-form {
		padding: 30px 0;
	}
}
h1 {
	text-align: center;
}
.back-connexion {
	margin-top: 15px !important;
}
</style>
