<template>
	<v-card class="mx-auto">
		<div v-if="proposal.image">
			<v-img
				class="white--text align-end"
				height="200px"
				:src="proposal.image"
			>
			</v-img>
		</div>
		<div v-else>
			<v-img
				class="white--text align-end"
				height="200px"
				src="../assets/img/todogift_picture.png"
			>
			</v-img>
		</div>
		
		<div :class="classStatus">
			{{ this.getLabelStatut }}
		</div>
		<v-card-title class="pb-1">
			<h3>{{ proposal.title }}</h3>
		</v-card-title>
		<v-card-text class="text--primary">
			<div>{{ proposal.description }}</div>
		</v-card-text>
	</v-card>
</template>

<script>
export default {
	name: "Card",
	props: {
		proposal: Object,
	},
	created: function () {
		this.getTotalPrice;
	},
	computed: {
		getTotalPrice: function () {
			const price = this.proposal.price;

			const reducer = (previousValue, currentValue) =>
				previousValue + currentValue.price;
			const total = this.proposal.reservations.reduce(reducer, 0);

			return total + " / " + price + "€";
		},
		classStatus: function () {
			return `status status-${this.proposal.status}`;
		},
		getLabelStatut: function () {
			switch (this.proposal.status) {
				case "pending":
					return this.getTotalPrice;
				case "complete":
					return `Montant atteint (${this.proposal.price}€)`;
				default:
					return `Acheté (${this.proposal.price}€)`;
			}
		},
	},
};
</script>

<style scoped lang="scss">
.status {
	color: white;
	padding: 5px 20px;
	font-weight: bold;

	&-pending {
		background-color: #e77e22;
	}
	&-paid {
		background-color: #26b05f;
	}
	&-complete {
		background-color: #2880b9;
	}
}
</style>
