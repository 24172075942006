<template>
	<div class="proposals">
		<div class="pb-7 section-name">
			<h2>Propositions de cadeaux</h2>
			<router-link
				class="router-link"
				:to="'/events/' + eventId + '/proposals/create'"
			>
				<Button value="Ajouter une proposition" color="primary" />
			</router-link>
		</div>
		<v-row>
			<v-col cols="12" sm="4" v-for="proposal in proposals" :key="proposal.id">
				<router-link
					class="router-link"
					:to="'/events/' + eventId + '/proposals/' + proposal.id"
				>
					<Card :proposal="proposal" />
				</router-link>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import Card from "../../../components/Card.vue";
import Button from "../../../components/Button.vue";

export default {
	name: "ProposalList",
	components: {
		Card,
		Button,
	},
	data: () => ({
		proposalsForList: [],
	}),
	props: {
		proposals: Array,
		eventId: String,
	},
	inject: ["valuesToast"],
	methods: {},
};
</script>

<style scoped lang="scss">
.proposals {
	margin: auto;
	margin-top: 60px;
}
.section-name {
	display: flex;
}
$primary-color: #17ac93;
$secondary-color: #1a6573;
.event-description {
	background-color: white;
	border-radius: 7px;
	padding: 30px 30px;
	margin-bottom: 50px;
}
.tables-event {
	justify-content: space-between;
}
.container {
	max-width: 100%;
	margin-bottom: 50px;
}
.v-sheet {
	border: none !important;
}
</style>
