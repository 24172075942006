<template>
	<div class="auth-form-container">
		<div class="subscription-form">
			<VueMik
				:initialValues="initialValues"
				:onSubmit="onSubmit"
				v-slot="{ invalid }"
			>
				<h1>Inscription</h1>
				<div class="content-form">
					<div>
						<Input
							rules="required|min:2"
							fieldName="Nom"
							value="lastName"
							:onSubmit="onSubmit"
						/>
						<Input
							rules="required|email"
							fieldName="Email"
							value="email"
							type="email"
							:onSubmit="onSubmit"
						/>
						<Input
							rules="required|min:8"
							fieldName="Mot de passe"
							value="password"
							type="password"
							:onSubmit="onSubmit"
						/>
					</div>
					<div>
						<Input
							rules="required|min:2"
							fieldName="Prénom"
							value="firstName"
							:onSubmit="onSubmit"
						/>
						<DatePicker value="birthdate" label="Date de naissance" />
						<Input
							:rules="'required|is:' + initialValues.password"
							fieldName="Confirmation du mot de passe"
							value="confirmPassword"
							type="password"
							:onSubmit="onSubmit"
						/>
					</div>
				</div>
				<div class="bottom-section">
					<Button
						type="Submit"
						:disabled="invalid"
						rules="required|email"
						value="S'inscrire"
						v-show="!isLoading"
					/>
					<Loader color="primary" v-show="isLoading" />
					<div class="back-connexion">
						<span>Déjà un compte ?</span>
						<a href="/connexion">Connectez-vous.</a>
					</div>
				</div>
			</VueMik>
		</div>
	</div>
</template>

<script>
import VueMik from "../../components/VueMik.vue";
import Input from "../../components/fields/Input.vue";
import DatePicker from "../../components/fields/DatePicker.vue";
import Button from "../../components/Button.vue";
import AuthService from "../../services/auth-service";
import Loader from "../../components/Loader";

export default {
	name: "Subscription",
	components: {
		VueMik,
		Input,
		DatePicker,
		Button,
		Loader,
	},
	inject: ["valuesToast"],
	data: () => ({
		initialValues: {
			nom: "",
			firstName: "",
			password: "",
			confirmPassword: "",
			email: "",
			//format 2005-05-06
		},
		isLoading: false,
		birthDate: "",
	}),
	methods: {
		onSubmit: async function (datas) {
			datas["gender"] = "homme";
			const authService = new AuthService();
			try {
				this.isLoading = true;
				await authService.register(datas);
				this.valuesToast.typeAlert = "success";
				this.valuesToast.text = "Votre compte à bien été crée !";
				this.valuesToast.toastValue = true;
				this.$router.push("connexion");
			} catch (error) {
				if (error.response.status === 422) {
					this.valuesToast.typeAlert = "error";
					this.valuesToast.text =
						"Cet email existe déjà. Veuillez en saisir un autre.";
					this.valuesToast.toastValue = true;
				} else {
					this.valuesToast.typeAlert = "error";
					this.valuesToast.text =
						"Un problème est surevenu, veuillez contacter un administrateur.";
					this.valuesToast.toastValue = true;
				}
			}
			this.isLoading = false;
		},
	},
};
</script>

<style scoped lang="scss">
$primary-color: #17ac93;
.auth-form-container {
	display: flex;
	justify-content: center;
	.subscription-form {
		display: flex;
		justify-content: center;
		background-color: rgb(255, 255, 255);
		border-radius: 5px;
		width: 800px;
		padding: 60px 0;

		.content-form {
			display: flex;
			padding: 30px 0;
		}
	}
	h1 {
		text-align: center;
	}
	.bottom-section {
		display: flex;
		flex-direction: column;
		justify-content: center;
		.back-connexion {
			display: flex;
			justify-content: center;
			margin-top: 15px !important;
			font-size: 15px;
			span {
				margin-right: 5px;
			}
			a {
				color: $primary-color !important;
				font-weight: bold;
				&:hover {
					text-decoration: underline !important;
				}
			}
		}
	}
}
</style>
