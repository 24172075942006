<template>
  <validation-provider
    v-slot="{ errors }"
    :name="$props.fieldName"
    :onSubmit="onSubmit"
    :rules="rules"
  >
    <v-flex xs12 class="text-xs-center text-sm-center text-md-center text-lg-center">
      <v-text-field label="Selectionne une image" @click='pickFile' v-model='imageName' prepend-icon="mdi-camera"></v-text-field>
      <input
        type="file"
        style="display: none"
        ref="image"
        accept="image/*"
        :error-messages="errors"
        @change="onFilePicked"
      >
      <img class="image-upload" :src="imageUrl" height="150" v-show="imageUrl"/>
    </v-flex>
  </validation-provider>
</template>

<script>
  import { ValidationProvider } from 'vee-validate'

  export default {
    name: 'UploadImage',
    data: () => ({
        imageName: '',
        imageUrl: '',
        imageFile: ''
    }),
    inject: ['values'],
    components: {
      ValidationProvider
    },
    props: {
      onSubmit: Function,
      fieldName: String,
      value: String,
      rules: String
    },
    methods: {
      pickFile () {
        this.$refs.image.click ()
      },
      onFilePicked (e) {
        const files = e.target.files
        if(files[0] !== undefined) {
          this.imageName = files[0].name
          if(this.imageName.lastIndexOf('.') <= 0) {
              return
          }
          const fr = new FileReader ()
          fr.readAsDataURL(files[0])
          fr.addEventListener('load', () => {
              this.imageUrl = fr.result
              this.imageFile = files[0]
          })
        } else {
          this.imageName = ''
          this.imageFile = ''
          this.imageUrl = ''
        }
      }
    },
    watch: {
      imageUrl: function(val) {
        this.values[this.$props.value] = {
          imageName: this.imageName,
          image: val
        };
        
      },
    },
    mounted() {
      this.imageUrl = this.values[this.$props.value];
    },
  }
</script>

<style scoped>
  .image-upload {
    height: 80px;
  }
</style>