import axios from "axios";
import getBaseUrl from "./settings-service";

export default class EventService {
	all(idUser) {
		return axios
			.get(`${getBaseUrl()}users/${idUser}/events`)
			.then((response) => {
				let data = response.data;
				return data["hydra:member"];
			});
	}
	getEventForGuests() {
		return axios.get(`${getBaseUrl()}events/guests/1`).then((response) => {
			let data = response.data;
			return data["hydra:member"];
		});
	}

	get(idEvent) {
		return axios.get(`${getBaseUrl()}events/${idEvent}`).then((response) => {
			return response.data;
		});
	}
	put(body, id) {
		return axios.put(`${getBaseUrl()}events/${id}`, body).then((response) => {
			return response;
		});
	}
	post(body) {
		return axios.post(`${getBaseUrl()}events`, body).then((response) => {
			return response;
		});
	}

	delete(id) {
		return axios.delete(`${getBaseUrl()}events/${id}`).then((response) => {
			return response;
		});
	}

	updateRecipients(id, body) {
		return axios
			.put(`${getBaseUrl()}events/${id}/recipients`, body)
			.then((response) => {
				return response;
			});
	}
	updateGuests(id, body) {
		return axios
			.put(`${getBaseUrl()}events/${id}/guests`, body)
			.then((response) => {
				return response;
			});
	}
}
