<template>
	<validation-observer ref="observer" v-slot="{ invalid }">
		<form @submit.prevent="submit">
			<slot :invalid="invalid"></slot>
		</form>
	</validation-observer>
</template>

<script>
import {
	required,
	digits,
	email,
	max,
	min,
	is,
	regex,
	between,
	integer,
} from "vee-validate/dist/rules";
import { extend, ValidationObserver, setInteractionMode } from "vee-validate";

setInteractionMode("eager");

extend("digits", {
	...digits,
	message: "{_field_} needs to be {length} digits. ({_value_})",
});

extend("required", {
	...required,
	message: "{_field_} ne peut pas être vide",
});

extend("max", {
	...max,
	message: "{_field_} ne doit pas faire plus de {length} caractères",
});

extend("min", {
	...min,
	message: "{_field_} ne doit pas faire moins de {length} caractères",
});

extend("between", {
	...between,
	message: "{_field_} may not be lower than {length} characters",
});

extend("is", {
	...is,
	message: "{_field_} ne correspond au mot de passe",
});

extend("regex", {
	...regex,
	message: "{_field_} {_value_} does not match {regex}",
});

extend("email", {
	...email,
	message: "Email doit être valide",
});

extend("integer", {
	...integer,
	message: "Le champs doit être un nombre",
});

export default {
	name: "VueMik",
	data: () => ({
		values: {},
	}),
	components: {
		ValidationObserver,
	},
	props: {
		initialValues: Object,
		onSubmit: Function,
	},
	provide() {
		this.values = this.$props.initialValues;
		return {
			values: this.values,
		};
	},
	methods: {
		submit() {
			this.$props.onSubmit(this.values);
		},
	},
};
</script>

<style scoped>
.form {
	padding: 20px;
	background-color: rgb(255, 255, 255);
	border-radius: 3px;
}
</style>
