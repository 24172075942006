import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import NoSideBar from "./views/layouts/NoSideBar.vue";
import Default from "./views/layouts/Default.vue";
import Vuex from "vuex";

Vue.component("nosidebar-layout", NoSideBar);
Vue.component("default-layout", Default);

Vue.config.productionTip = false;

Vue.use(Vuex);

const store = new Vuex.Store({
	state: {
		user: {},
		firends: [],
	},
	mutations: {
		storeUser(state, user) {
			state.user = user;
		},
		storeFriends(state, friends) {
			state.friends = friends;
		},
	},
});

new Vue({
	vuetify,
	router,
	store,
	render: (h) => h(App),
}).$mount("#app");
