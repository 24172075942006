<template>
	<div>
		<!-- Modals -->
		<Modal
			:onSubmit="onSubmitDelete"
			:dialog="openDeleteFriend"
			:cancel="switchModal"
			title="Es-tu sûr de vouloir supprimer cet ami ?"
			nameConfirm="Supprimer"
		/>
		<Modal
			:onSubmit="onSubmitDelete"
			:dialog="openRefuseReceive"
			:cancel="switchModal"
			title="Es-tu sûr de vouloir supprimer cette demande d'ami ?"
			nameConfirm="Supprimer"
		/>
		<Modal
			:onSubmit="onSubmitDelete"
			:dialog="openDeleteSend"
			:cancel="switchModal"
			title="Es-tu sûr de vouloir supprimer votre demande d'ami ?"
			nameConfirm="Supprimer"
		/>
		<Modal
			:onSubmit="onSumbitAcceptFriend"
			:dialog="openAcceptReceive"
			:cancel="switchModal"
			title="Es-tu sûr de vouloir accepter cette demande d'ami ?"
			nameConfirm="Accepter"
		/>
		<Modal
			:onSubmit="onSubmitAdd"
			:dialog="openAdd"
			:cancel="switchModal"
			:initialValues="initialValues"
			title="Ajout d'un ami"
			nameConfirm="Ajouter"
		>
			<Input rules="required|email" fieldName="Email" value="email" />
		</Modal>
		<!-- Component -->
		<div class="header-component">
			<h1>Amis</h1>
			<Button
				:function="switchModal"
				value="Ajouter un ami"
				color="primary"
				:paramFunction="null"
				secondParamFunction="sendFriendRequest"
			/>
		</div>
		<div class="list-content">
			<v-card color="basil">
				<v-tabs v-model="tab" background-color="transparent" color="basil" grow>
					<v-tab v-for="item in items" :key="item">
						{{ item }}
					</v-tab>
				</v-tabs>
				<v-tabs-items v-model="tab">
					<v-tab-item>
						<v-card color="basil" flat>
							<v-card-text>
								<Table
									name="Liste d'amis"
									:datas="friends"
									:header="header_friends"
									:switchModal="switchModal"
									:itemPerPage="[5]"
									:loading="loading"
								>
									<template v-slot:items="slotProps">
										<td v-if="slotProps.item.image">
											<img
												class="img-table-friend"
												:src="slotProps.item.image"
												alt=""
											/>
										</td>
										<td v-else>
											<img
												class="img-table-friend"
												src="../../assets/img/user-icon.png"
												alt=""
											/>
										</td>
										<td>{{ slotProps.item.firstName }}</td>
										<td>{{ slotProps.item.lastName }}</td>
										<td>{{ slotProps.item.email }}</td>
										<td>{{ slotProps.item.birthDate }}</td>
										<td>
											<v-icon
												small
												@click="
													switchModal(
														slotProps.item.user_friends_id,
														'deleteFriend'
													)
												"
											>
												mdi-delete
											</v-icon>
										</td>
									</template>
								</Table>
							</v-card-text>
						</v-card>
					</v-tab-item>
					<v-tab-item>
						<v-card color="basil" flat>
							<v-card-text>
								<Table
									name="Liste de vos requêtes d'amis"
									:datas="friendsPendingSends"
									:header="header_friendsPendingSends"
									:switchModal="switchModal"
									:itemPerPage="[5, 10, 15, -1]"
								>
									<template v-slot:items="slotProps">
										<td v-if="slotProps.item.image">
											<img
												class="img-table-friend"
												:src="slotProps.item.image"
												alt=""
											/>
										</td>
										<td v-else>
											<img
												class="img-table-friend"
												src="../../assets/img/user-icon.png"
												alt=""
											/>
										</td>
										<td>{{ slotProps.item.firstName }}</td>
										<td>{{ slotProps.item.lastName }}</td>
										<td>{{ slotProps.item.email }}</td>
										<td>
											<v-icon
												small
												@click="switchModal(slotProps.item.id, 'refuseReceive')"
											>
												mdi-delete
											</v-icon>
										</td>
									</template>
								</Table>
							</v-card-text>
						</v-card>
					</v-tab-item>
					<v-tab-item>
						<v-card color="basil" flat>
							<v-card-text>
								<Table
									name="Liste d'amis"
									:datas="friendsPendingReceives"
									:header="header_friendsPendingReceives"
									:switchModal="switchModal"
									:itemPerPage="[5, 10, 15, -1]"
								>
									<template v-slot:items="slotProps">
										<td v-if="slotProps.item.image">
											<img
												class="img-table-friend"
												:src="slotProps.item.image"
												alt=""
											/>
										</td>
										<td v-else>
											<img
												class="img-table-friend"
												src="../../assets/img/user-icon.png"
												alt=""
											/>
										</td>
										<td>{{ slotProps.item.firstName }}</td>
										<td>{{ slotProps.item.lastName }}</td>
										<td>{{ slotProps.item.email }}</td>
										<td>
											<v-icon
												small
												class="mr-5"
												@click="switchModal(slotProps.item.id, 'acceptFriend')"
											>
												mdi-check
											</v-icon>
											<v-icon
												small
												@click="switchModal(slotProps.item.id, 'deleteSend')"
											>
												mdi-delete
											</v-icon>
										</td>
									</template>
								</Table>
							</v-card-text>
						</v-card>
					</v-tab-item>
				</v-tabs-items>
			</v-card>
		</div>
	</div>
</template>
<script>
import Table from "../../components/Table.vue";
import Button from "../../components/Button.vue";
import Modal from "../../components/Modal.vue";
import Input from "../../components/fields/Input.vue";
import FriendService from "../../services/user-friend-service";
import getDateTimeFormat from "../../helpers/date-helper";

const friendService = new FriendService();

export default {
	name: "FriendList",
	data: () => ({
		tab: null,
		initialValues: {
			email: "",
		},
		item: "",
		loading: true,
		openDeleteFriend: false,
		openRefuseReceive: false,
		openDeleteSend: false,
		openAcceptReceive: false,
		openAdd: false,
		modalOpened: null,
		items: [
			"Mes amis",
			"Mes envoies de demandes d'amis ",
			"Mes demandes d'amis",
		],
		friends: [],
		friendsPendingReceives: [],
		friendsPendingSends: [],
		header_friendsPendingReceives: [
			{
				text: "",
			},
			{
				text: "Prénom",
				align: "start",
				value: "firstName",
			},
			{ text: "Nom", value: "lastName" },
			{ text: "Email", value: "email" },
			{ text: "Actions", value: "actions", sortable: false },
		],
		header_friendsPendingSends: [
			{
				text: "",
			},
			{
				text: "Prénom",
				align: "start",
				value: "firstName",
			},
			{ text: "Nom", value: "lastName" },
			{ text: "Email", value: "email" },
			{ text: "Actions", value: "actions", sortable: false },
		],
		header_friends: [
			{
				text: "",
			},
			{
				text: "Prénom",
				align: "start",
				value: "firstName",
			},
			{ text: "Nom", value: "lastName" },
			{ text: "Email", value: "email" },
			{ text: "Date de naissance", value: "birthDate" },
			{ text: "Actions", value: "actions", sortable: false },
		],
	}),
	inject: ["valuesToast"],
	components: {
		Button,
		Input,
		Modal,
		Table,
	},
	created: async function () {
		await this.getFriends();
		await this.getFriendsPendingSends();
		await this.getFriendsPendingReceives();
	},
	methods: {
		getFriends: async function () {
			this.loading = true;
			let friends = await friendService.getFriends(this.$store.state.user.id);

			this.friends = this.formatDatas(friends);
			this.loading = false;
		},
		getFriendsPendingSends: async function () {
			this.loading = true;
			let friendsPendingSends = await friendService.getFriendsPendingSends(
				this.$store.state.user.id
			);
			friendsPendingSends = friendsPendingSends.map((friend) => {
				return {
					...friend,
					firstName: friend.recipient.firstName,
					lastName: friend.recipient.lastName,
					email: friend.recipient.email,
				};
			});
			this.friendsPendingSends = this.formatDatas(friendsPendingSends);
			this.loading = false;
		},
		getFriendsPendingReceives: async function () {
			this.loading = true;
			let friendsPendingReceives =
				await friendService.getFriendsPendingReceives(
					this.$store.state.user.id
				);
			friendsPendingReceives = friendsPendingReceives.map((friend) => {
				return {
					...friend,
					firstName: friend.sender.firstName,
					lastName: friend.sender.lastName,
					email: friend.sender.email,
				};
			});
			this.friendsPendingReceives = this.formatDatas(friendsPendingReceives);
			this.loading = false;
		},
		onSubmitDelete: async function () {
			if (this.modalOpened === "deleteFriend") {
				await friendService.delete(this.item);
				await this.getFriends();
				this.refreshFriendsStore();
			} else if (this.modalOpened === "refuseReceive") {
				await friendService.delete(this.item);
				this.getFriendsPendingSends();
			} else if (this.modalOpened === "deleteSend") {
				await friendService.delete(this.item);
				this.getFriendsPendingReceives();
			}
			this.switchModal(null, this.modalOpened);
			this.valuesToast.typeAlert = "success";
			this.valuesToast.toastValue = true;
		},
		formatDatas(friends) {
			return friends.map((friend) => {
				return {
					...friend,
					birthDate: getDateTimeFormat(friend.birthDate),
				};
			});
		},
		onSubmitAdd: async function () {
			await friendService.post({ email: this.initialValues.email });
			this.getFriendsPendingSends();
			this.switchModal(null, this.modalOpened);
			this.valuesToast.typeAlert = "success";
			this.valuesToast.toastValue = true;
		},
		onSumbitAcceptFriend: async function () {
			await friendService.patch(this.item);
			this.getFriendsPendingReceives();
			await this.getFriends();
			this.refreshFriendsStore();
			this.switchModal(null, this.modalOpened);
			this.valuesToast.typeAlert = "success";
			this.valuesToast.toastValue = true;
		},
		switchModal(item = null, status = null) {
			this.modalOpened = status;
			if (typeof item == "number") {
				this.item = item;
			} else {
				this.item = "";
			}
			if (status == "addFriend") {
				this.openAdd = !this.openAdd;
			} else if (status == "deleteFriend") {
				this.openDeleteFriend = !this.openDeleteFriend;
				this.valuesToast.text = "Votre ami a bien été supprimé !";
			} else if (status == "refuseReceive") {
				this.openRefuseReceive = !this.openRefuseReceive;
				this.valuesToast.text = "Demande d'ami refusée !";
				this.valuesToast.text = "Votre ami a bien été supprimé !";
			} else if (status == "deleteSend") {
				this.openDeleteSend = !this.openDeleteSend;
				this.valuesToast.text = "La demande d'ami a bien été supprimé !";
			} else if (status == "acceptFriend") {
				this.openAcceptReceive = !this.openAcceptReceive;
				this.valuesToast.text = "Demande d'ami acceptée !";
			} else if (status == "sendFriendRequest") {
				this.openAdd = !this.openAdd;
				this.valuesToast.text = "Demande d'ami envoyée !";
			} else {
				this.openAdd = false;
				this.openDeleteFriend = false;
				this.openRefuseReceive = false;
				this.openDeleteSend = false;
				this.openAcceptReceive = false;
			}
		},
		refreshFriendsStore() {
			this.$store.commit("storeFriends", this.friends);
		},
	},
};
</script>

<style scoped lang="scss">
$primary-color: #17ac93;
$secondary-color: #1a6573;

.header-component {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding-bottom: 20px;
}
.list-content {
	display: flex;
	justify-content: center;
	padding-top: 50px;
}
.v-card {
	box-shadow: none !important;
	width: 100%;
}
.v-tab {
	padding: 0 40px;
}
.v-tab--active {
	background-color: $secondary-color;
	color: white !important;
}
.img-table-friend {
	width: 30px;
	height: 30px;
	object-fit: cover;
	border-radius: 100px;
}
</style>
