import axios from "axios";
import getBaseUrl from "./settings-service";

export default class ReservationService {
	patchPrice(id, body) {
		return axios
			.patch(`${getBaseUrl()}reservations/${id}`, body)
			.then((response) => response);
	}
	post(body) {
		return axios
			.post(`${getBaseUrl()}reservations`, body)
			.then((response) => response);
	}
	delete(id) {
		return axios
			.delete(`${getBaseUrl()}reservations/${id}`)
			.then((response) => response);
	}
}
