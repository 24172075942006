import axios from "axios";

export default function getBaseUrl() {
	return process.env.VUE_APP_API_URL + "api/";
}

export const httpClient = axios.create({
	baseURL: getBaseUrl(),
	headers: {
		//Authorization: 'Bearer {token}',
		//timeout: 1000, // indicates, 1000ms ie. 1 second
		"Content-Type": "application/json",
	},
});
