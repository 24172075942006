<template>
	<div class="connexion-form-container">
		<div class="connexion-form">
			<VueMik
				:initialValues="initialValues"
				:onSubmit="onSubmit"
				v-slot="{ invalid }"
			>
				<div class="subscription_logo">
					<img src="../../assets/img/entire_logo_todogift.png" alt="" />
				</div>
				<div class="content-form">
					<Input
						fieldName="Email"
						type="email"
						value="email"
						:onSubmit="onSubmit"
					/>
					<Input
						fieldName="Password"
						type="password"
						value="password"
						:onSubmit="onSubmit"
					/>
				</div>
				<div class="bottom-section">
					<Button
						type="Submit"
						:disabled="invalid"
						value="Connexion"
						color="primary"
						v-show="!isLoading"
					/>
					<Loader color="primary" v-show="isLoading" />
					<div class="subscription">
						<span>Aucun compte ? </span>
						<a href="/subscription">Inscrivez-vous</a>
					</div>
				</div>
			</VueMik>
		</div>
	</div>
</template>

<script>
import VueMik from "../../components/VueMik.vue";
import Input from "../../components/fields/Input.vue";
import Button from "../../components/Button.vue";
import AuthService from "../../services/auth-service";
import Loader from "../../components/Loader";

export default {
	name: "Connexion",
	data: () => ({
		initialValues: {},
		isLoading: false,
	}),
	inject: ["valuesToast"],
	components: {
		VueMik,
		Input,
		Button,
		Loader,
	},
	methods: {
		onSubmit: async function (datas) {
			const authService = new AuthService();
			try {
				this.isLoading = true;
				await authService.login(datas);
				this.valuesToast.typeAlert = "success";
				this.valuesToast.text = "Vous êtes connecté !";
				this.valuesToast.toastValue = true;
				this.$router.push("/events");
			} catch (error) {
				if (error.response.status === 401) {
					this.valuesToast.typeAlert = "error";
					this.valuesToast.text = "Votre email ou mot de passe est incorrect";
					this.valuesToast.toastValue = true;
				} else {
					this.valuesToast.typeAlert = "error";
					this.valuesToast.text =
						"Un problème est surevenu, veuillez contacter un administrateur.";
					this.valuesToast.toastValue = true;
				}
			}
			this.isLoading = false;
		},
	},
};
</script>

<style scoped lang="scss">
.connexion-form-container {
	display: flex;
	justify-content: center;
	.connexion-form {
		display: flex;
		justify-content: center;
		background-color: rgb(255, 255, 255);
		width: 500px;
		border-radius: 5px;
		padding: 50px;

		.subscription_logo {
			display: flex;
			justify-content: center;
			img {
				width: 250px;
			}
		}
		.content-form {
			padding: 30px 0;
		}
		.bottom-section {
			display: flex;
			flex-direction: column;
			justify-content: center;
			.forgot-password {
				margin-top: 20px;
				display: flex;
				justify-content: center;
			}
			.subscription {
				display: flex;
				justify-content: center;
				margin-top: 15px !important;
				font-size: 15px;
				span {
					margin-right: 5px;
				}
				a {
					color: #55ad94 !important;
					font-weight: bold;
					&:hover {
						text-decoration: underline !important;
					}
				}
			}
		}
	}
	h1 {
		text-align: center;
	}
}
</style>
