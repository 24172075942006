<template>
	<div>
		<!-- Modals -->
		<div v-if="!loading">
			<Modal
				:onSubmit="onSubmitAddPart"
				:dialog="openAddPart"
				:cancel="switchModal"
				:initialValues="initialValues"
				title="Ajout d'un participant"
				nameConfirm="Ajouter"
			>
				<Select
					rules="required"
					value="eventGuests"
					:listValues="friendsFiltered"
					fieldName="Participant(s)"
					itemText="fullName"
					:onSubmit="onSubmitAddPart"
				/>
			</Modal>
			<Modal
				:onSubmit="onSubmitAddDesti"
				:dialog="openAddDesti"
				:cancel="switchModal"
				:initialValues="initialValues"
				title="Ajout d'un destinataire"
				nameConfirm="Ajouter"
			>
				<Select
					rules="required"
					value="eventRecipients"
					itemText="fullName"
					:listValues="friendsFiltered"
					fieldName="Destinataires"
					:onSubmit="onSubmitAddDesti"
				/>
			</Modal>
			<Modal
				:onSubmit="onSubmitDelete"
				:dialog="openDelete"
				:cancel="switchModal"
				:title="titleDelete"
				nameConfirm="Retirer"
			/>
		</div>
		<!-- Component -->
		<div class="header-component">
			<h1>Evènement : {{ event.name }}</h1>
		</div>
		<div class="container-component">
			<div class="event-description">
				<p>{{ event.description }}</p>
			</div>
			<v-container>
				<v-row class="tables-event">
					<v-col cols="12" sm="5">
						<v-card class="pa-2" outlined tile>
							<Table
								:datas="event.eventGuests"
								:header="guestsHeader"
								secondParam="addPart"
								buttonName="Ajouter"
								:itemPerPage="[5]"
								:loading="loading"
							>
								<template v-slot:headerTable>
									<div class="header-toolbar">
										<v-toolbar-title>Participants</v-toolbar-title>
										<v-divider class="mx-4" inset vertical></v-divider>
										<v-spacer></v-spacer>
										<Button
											v-if="
												!noMoreFriendsGuests &&
												event.owner &&
												event.owner.id === userId
											"
											:function="switchModal"
											value="Ajouter"
											color="primary"
											:paramFunction="null"
											secondParamFunction="addPart"
										/>
										<!-- Slot Actions -->
									</div>
								</template>
								<template v-slot:items="slotProps">
									<td>{{ slotProps.item.firstName }}</td>
									<td>{{ slotProps.item.lastName }}</td>
									<td>
										<v-icon
											v-if="
												event.owner.id === userId &&
												slotProps.item.id !== user.id
											"
											small
											@click="switchModal(slotProps.item.id, 'guest')"
										>
											mdi-delete
										</v-icon>
									</td>
								</template>
							</Table>
						</v-card>
					</v-col>
					<v-col cols="12" sm="5">
						<v-card class="pa-2" outlined tile>
							<Table
								:datas="event.eventRecipients"
								:header="recipientsHeader"
								:switchModal="switchModal"
								secondParam="addDesti"
								buttonName="Ajouter"
								:itemPerPage="[5]"
								:loading="loading"
							>
								<!-- Slot Header -->
								<template v-slot:headerTable>
									<div class="header-toolbar">
										<v-toolbar-title>Destinataires</v-toolbar-title>
										<v-divider class="mx-4" inset vertical></v-divider>
										<v-spacer></v-spacer>
										<Button
											v-if="
												!noMoreFriendsRecipients &&
												event.owner &&
												event.owner.id === userId
											"
											:function="switchModal"
											value="Ajouter"
											color="primary"
											:paramFunction="null"
											secondParamFunction="addDesti"
										/>
									</div>
								</template>
								<template v-slot:items="slotProps">
									<td>{{ slotProps.item.firstName }}</td>
									<td>{{ slotProps.item.lastName }}</td>
									<td>{{ slotProps.item.birthdate }}</td>
									<td>
										<v-icon
											v-if="event.owner.id === userId"
											small
											@click="switchModal(slotProps.item.id, 'recipient')"
										>
											mdi-delete
										</v-icon>
									</td>
								</template>
							</Table>
						</v-card>
					</v-col>
				</v-row>
				<ProposalList :proposals="proposals" :eventId="eventId" />
			</v-container>
		</div>
	</div>
</template>

<script>
import Table from "../../components/Table.vue";
import Button from "../../components/Button.vue";
import Modal from "../../components/Modal.vue";
import Select from "../../components/fields/Select.vue";
import EventService from "../../services/event-service.js";
import EventRecipientService from "../../services/event-recipient-service.js";
import EventGuestService from "../../services/event-guest-service.js";
import getDateTimeFormat from "../../helpers/date-helper";
import { formatUserArrayForApi } from "../../helpers/array-helper.js";
import ProposalService from "../../services/proposal-service.js";
import ProposalList from "./proposals/ProposalList.vue";

const eventService = new EventService();
const eventRecipientService = new EventRecipientService();
const eventGuestService = new EventGuestService();
const proposalService = new ProposalService();

export default {
	name: "EventShow",
	components: {
		Table,
		Button,
		Modal,
		Select,
		ProposalList,
	},
	data: () => ({
		item: {},
		eventId: "",
		openDelete: false,
		openAddPart: false,
		openAddDesti: false,
		friends: [],
		friendsFiltered: [],
		initialValues: {
			eventGuests: [],
			eventRecipients: [],
		},
		user: {},
		event: {},
		eventGuests: [],
		eventRecipients: [],
		loading: true,
		userId: "",
		recipientsHeader: [
			{
				text: "Prénom",
				align: "start",
			},
			{
				text: "Nom",
			},
			{
				text: "Anniversaire",
			},
			{ text: "Actions", sortable: false },
		],
		guestsHeader: [
			{
				text: "Prénom",
				align: "start",
			},
			{ text: "Nom" },
			{ text: "Actions", sortable: false },
		],
	}),
	inject: ["valuesToast"],
	created: async function () {
		if (this.$route.params.id) {
			this.user = this.$store.state.user;
			this.userId = this.$store.state.user.id;
			this.eventId = this.$route.params.id;
			this.friends = this.$store.state.friends;
			try {
				await this.getEvent();
			} catch (e) {
				this.$router.go(-1);
			}
		}
	},
	methods: {
		getEvent: async function () {
			this.loading = true;
			const event = await eventService.get(this.eventId);
			const proposals = await proposalService.getEventProposals(this.eventId);
			const eventRecipients = event.eventRecipients.map((recipient) => {
				return {
					...recipient.person,
					birthdate: getDateTimeFormat(recipient.person.birthdate, false),
				};
			});
			const eventGuests = event.eventGuests.map((guest) => guest.person);

			this.eventRecipients = event.eventRecipients;
			this.eventGuests = event.eventGuests;
			const eventGuestsWithUser = [...eventGuests, event.owner];
			this.initialValues = {
				eventRecipients,
				eventGuests,
				proposals,
			};
			this.event = {
				...event,
				eventRecipients,
				eventGuests: eventGuestsWithUser,
				proposals,
			};
			this.loading = false;
		},
		onSubmitDelete: async function () {
			// try {
			if (this.item.entity === "recipient") {
				const idEventRecipient = this.eventRecipients.filter(
					(e) => e.person.id === this.item.id
				)[0]["id"];
				await eventRecipientService.delete(idEventRecipient);
			} else {
				const idEventGuest = this.eventGuests.filter(
					(e) => e.person.id === this.item.id
				)[0]["id"];
				await eventGuestService.delete(idEventGuest);
			}
			this.openDelete = false;
			this.getEvent();
			this.valuesToast.typeAlert = "success";
			this.valuesToast.text =
				this.item.entity === "recipient"
					? "Destinataire supprimé"
					: "Participant supprimé !";
			this.valuesToast.toastValue = true;
			// } catch (e) {
			// 	this.valuesToast.typeAlert = "error";
			// 	this.valuesToast.text =
			// 		"Un problème est surevenu, veuillez contacter un administrateur.";
			// 	this.valuesToast.toastValue = true;
			// }
		},
		onSubmitAddPart: async function (datas) {
			try {
				let guests = formatUserArrayForApi(datas.eventGuests);
				await eventService.updateGuests(this.eventId, {
					eventGuests: guests,
				});

				this.valuesToast.typeAlert = "success";
				this.valuesToast.text = "Participant(s) ajouté(s) !";
				this.valuesToast.toastValue = true;
				this.openAddPart = false;
				this.getEvent();
			} catch (error) {
				this.valuesToast.typeAlert = "error";
				this.valuesToast.text =
					"Un problème est surevenu, veuillez contacter un administrateur.";
				this.valuesToast.toastValue = true;
			}
		},
		onSubmitAddDesti: async function (datas) {
			try {
				let recipients = formatUserArrayForApi(datas.eventRecipients);

				await eventService.updateRecipients(this.eventId, {
					eventRecipients: recipients,
				});

				this.valuesToast.typeAlert = "success";
				this.valuesToast.text = "Déstinataire(s) ajouté(s) !";
				this.valuesToast.toastValue = true;
				this.openAddDesti = false;
				this.getEvent();
			} catch (error) {
				this.valuesToast.typeAlert = "error";
				this.valuesToast.text =
					"Un problème est surevenu, veuillez contacter un administrateur.";
				this.valuesToast.toastValue = true;
			}
		},
		switchModal(item = "", status = null) {
			if (typeof item == "number") {
				this.item = {
					id: item,
					entity: status,
				};
			} else {
				this.item = {};
			}

			if (status == "addPart") {
				this.friendsFiltered = this.filterFriendsGuests();
				this.openAddPart = !this.openAddPart;
			} else if (status == "addDesti") {
				this.friendsFiltered = this.filterFriendsRecipients();
				this.openAddDesti = !this.openAddDesti;
			} else if (["recipient", "guest"].includes(status)) {
				this.openDelete = !this.openDelete;
			} else {
				this.openDelete = false;
				this.openAddDesti = false;
				this.openAddPart = false;
			}
		},
		filterFriendsGuests() {
			const guestsId = this.initialValues.eventGuests.map((guest) => guest.id);
			return this.friends.filter((friend) => !guestsId.includes(friend.id));
		},
		filterFriendsRecipients() {
			const recipientsId = this.initialValues.eventRecipients.map(
				(recipient) => recipient.id
			);
			return this.friends.filter((friend) => !recipientsId.includes(friend.id));
		},
	},
	computed: {
		noMoreFriendsRecipients: function () {
			return this.filterFriendsRecipients().length === 0;
		},
		noMoreFriendsGuests: function () {
			return this.filterFriendsGuests().length === 0;
		},
		titleDelete: function () {
			return this.item.entity === "recipient"
				? "Etes-vous sûr de vouloir retirer ce destinataire ?"
				: "Etes-vous sûr de vouloir retirer ce participant ?";
		},
		proposals: function () {
			return this.event.proposals;
		},
	},
};
</script>

<style scoped lang="scss">
$primary-color: #17ac93;
$secondary-color: #1a6573;
.header-component {
	margin-bottom: 25px;
}
.event-description {
	background-color: white;
	border-radius: 7px;
	padding: 30px 30px;
	margin-bottom: 50px;
}
.tables-event {
	justify-content: space-between;
}
.container {
	max-width: 100%;
	margin-bottom: 50px;
}
.v-sheet {
	border: none !important;
}
</style>
