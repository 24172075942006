<template>
	<div class="comments-section">
		<div class="comment-card" v-for="comment in comments" :key="comment.id">
			<Comment :comment="comment" :openDelete="openDelete" :userId="userId" />
		</div>
	</div>
</template>

<script>
import Comment from "../../../components/Comment.vue";
export default {
	name: "CommentsList",
	components: {
		Comment,
	},
	props: {
		comments: Array,
		openDelete: Function,
		userId: String,
	},
};
</script>

<style scoped lang="scss">
.v-card {
	max-width: 100%;
}
.row {
	width: 100%;
	.v-sheet {
		width: 100%;
	}
}
</style>
